/******* STANDARD *******/

.hideme {
  display: none;
}

.showme {
  display: block;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.clear-both {
  clear: both;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.display-inline {
  display: inline-block;
  min-width: 250px;
}

.clear {
  clear: both;
  font-size: 1px;
  height: 1px;
  margin: 0;
  padding: 0;
  border: 0;
  line-height: 1px;
}

.clr {
  margin: 0;
  padding: 0;
  border: 0;
}

.flex {
  display: flex;
}

.wbs {
  margin: 0 0 8px 0 !important;
}

.wbss {
  margin: 0 0 16px 0 !important;
}

.wbsss {
  margin: 0 0 25px 0 !important;
}

.wbssss {
  margin: 0 0 32px 0 !important;
}

.wbsssss {
  margin: 0 0 64px 0 !important;
}

.wbssssss {
  margin: 0 0 128px 0 !important;
}

.wbsssssss {
  margin: 0 0 256px 0 !important;
}

.wbssssssss {
  margin: 0 0 512px 0 !important;
}

.wbs0 {
  margin: 0 !important;
}

.wbs4 {
  margin: 0 0 4px 0 !important;
}

.wbs8 {
  margin: 0 0 8px 0 !important;
}

.wbs10 {
  margin: 0 0 10px 0 !important;
}

.wbs12 {
  margin: 0 0 12px 0 !important;
}

.wbs25 {
  margin: 0 0 25px 0 !important;
}

.wts {
  margin: 8px 0 0 0 !important;
}

.wts10 {
  margin: 10px 0 0 0 !important;
}

.wts12,
.wtss {
  margin: 12px 0 0 0 !important;
}

.wts16,
.wtsss {
  margin: 16px 0 0 0 !important;
}

.wts25,
.wtssss {
  margin: 25px 0 0 0 !important;
}

.wts40,
.wtsssss {
  margin: 40px 0 0 0 !important;
}

.nbm {
  margin-bottom: 0 !important;
}

.wrapper8 {
  margin: 8px !important;
  padding: 0;
  border: 0;
}

.wrapper10 {
  margin: 10px !important;
  padding: 0;
  border: 0;
}

.wrapper12 {
  margin: 12px !important;
  padding: 0;
  border: 0;
}

.wrapper25 {
  margin: 25px !important;
  padding: 0;
  border: 0;
}

.marginLR0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.padding-right {
  padding-right: 8px;
}

.padding-left {
  padding-left: 8px;
}

.margin-right {
  margin-right: 8px;
}

.margin-left {
  margin-left: 8px;
}

.no-wrap,
.nowrap {
  white-space: nowrap;
}

.required {
  font-style: italic !important;
  color: red !important;
  font-size: 12px !important;
}

.invalid-entry {
  font-style: italic !important;
  color: red !important;
  font-size: 13px !important;
  white-space: nowrap;
}

.color-red {
  color: red !important;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.valign-top {
  vertical-align: top;
}

.valign-middle {
  vertical-align: middle;
}

/******* BAR: b&r solutions *******/

.bar-bold {
  font-weight: bold !important;
}

.bar-bold2 {
  font-weight: 500 !important;
}

.bar-em {
  font-style: italic !important;
}

.bar-hover-ul:hover {
  text-decoration: underline !important;
}

.bar-pointer {
  cursor: pointer !important;
}

/******* WIDTHS *******/

.m100 {
  min-width: 100px;
}

.m150 {
  min-width: 150px;
}

.w80 {
  width: 80px !important;
}

.w85 {
  width: 85px !important;
}

.w90 {
  width: 90px !important;
}

.w95 {
  width: 95px !important;
}

.w98p {
  width: 98% !important;
}

.w5p {
  width: 5% !important;
}

.w10p {
  width: 10% !important;
}

.w15p {
  width: 15% !important;
}

.w20p {
  width: 20% !important;
}

.w25p {
  width: 25% !important;
}

.w30p {
  width: 30% !important;
}

.w35p {
  width: 35% !important;
}

.w40p {
  width: 40% !important;
}

.w45p {
  width: 45% !important;
}

.w50p {
  width: 50% !important;
}

.w55p {
  width: 55% !important;
}

.w60p {
  width: 60% !important;
}

.w65p {
  width: 65% !important;
}

.w70p {
  width: 70% !important;
}

.w75p {
  width: 75% !important;
}

.w80p {
  width: 80% !important;
}

.w85p {
  width: 85% !important;
}

.w90p {
  width: 90% !important;
}

.w95p {
  width: 95% !important;
}

.w100p {
  width: 100% !important;
}

.w25 {
  width: 25px !important;
}

.w50 {
  width: 50px !important;
}

.w75 {
  width: 75px !important;
}

.w100 {
  width: 100px !important;
}

.w125 {
  width: 125px !important;
}

.w150 {
  width: 150px !important;
}

.w175 {
  width: 175px !important;
}

.w200 {
  width: 200px !important;
}

.w225 {
  width: 225px !important;
}

.w250 {
  width: 250px !important;
}

.w275 {
  width: 275px !important;
}

.w300 {
  width: 300px !important;
}

.w325 {
  width: 325px !important;
}

.w350 {
  width: 350px !important;
}

.w375 {
  width: 375px !important;
}

.w400 {
  width: 400px !important;
}

.w425 {
  width: 425px !important;
}

.w450 {
  width: 450px !important;
}

.w475 {
  width: 475px !important;
}

.w500 {
  width: 500px !important;
}

.w525 {
  width: 525px !important;
}

.w550 {
  width: 550px !important;
}

.w575 {
  width: 575px !important;
}

.w600 {
  width: 600px !important;
}

.w625 {
  width: 625px !important;
}

.w650 {
  width: 650px !important;
}

.w675 {
  width: 675px !important;
}

.w700 {
  width: 700px !important;
}

.w725 {
  width: 725px !important;
}

.w750 {
  width: 750px !important;
}

.w775 {
  width: 775px !important;
}

.w800 {
  width: 800px !important;
}

.w825 {
  width: 825px !important;
}

.w850 {
  width: 850px !important;
}

.w875 {
  width: 875px !important;
}

.w900 {
  width: 900px !important;
}

.w925 {
  width: 925px !important;
}

.w950 {
  width: 950px !important;
}

.w975 {
  width: 975px !important;
}

.w1000 {
  width: 1000px !important;
}

.w1025 {
  width: 1025px !important;
}

.w1050 {
  width: 1050px !important;
}

.w1075 {
  width: 1075px !important;
}

.w1100 {
  width: 1100px !important;
}

.w1125 {
  width: 1125px !important;
}

.w1150 {
  width: 1150px !important;
}

.w1175 {
  width: 1175px !important;
}

.w1200 {
  width: 1200px !important;
}

.w1225 {
  width: 1225px !important;
}

.w1250 {
  width: 1250px !important;
}

.w1275 {
  width: 1275px !important;
}

.w1300 {
  width: 1300px !important;
}

.w1325 {
  width: 1325px !important;
}

.w1350 {
  width: 1350px !important;
}

.w1375 {
  width: 1375px !important;
}

.w1400 {
  width: 1400px !important;
}

.w1425 {
  width: 1425px !important;
}

.w1450 {
  width: 1450px !important;
}

.w1475 {
  width: 1475px !important;
}

.w1500 {
  width: 1500px !important;
}

.w1525 {
  width: 1525px !important;
}

.w1550 {
  width: 1550px !important;
}

.w1575 {
  width: 1575px !important;
}

.w1600 {
  width: 1600px !important;
}

.w1625 {
  width: 1625px !important;
}

.w1650 {
  width: 1650px !important;
}

.w1675 {
  width: 1675px !important;
}

.w1700 {
  width: 1700px !important;
}

.w1725 {
  width: 1725px !important;
}

.w1750 {
  width: 1750px !important;
}

.w1775 {
  width: 1775px !important;
}

.w1800 {
  width: 1800px !important;
}

.w1825 {
  width: 1825px !important;
}

.w1850 {
  width: 1850px !important;
}

.w1875 {
  width: 1875px !important;
}

.w1900 {
  width: 1900px !important;
}

.w1925 {
  width: 1925px !important;
}

.w1950 {
  width: 1950px !important;
}

.w1975 {
  width: 1975px !important;
}

.w2000 {
  width: 2000px !important;
}

.blueborder {
  border: solid 1px blue;
}

.redborder {
  border: solid 1px red;
}

.greenborder {
  border: solid 1px green;
}