body {
  margin: 0;
}

/* top nav */

.topnav {
  overflow: hidden;
  background-color: #333;
}

.topnav .topnav-logo {
  margin: 0;
  border: 0;
  padding: 13px 20px 0 12px;
}

.topnav .topnav-logo img {
  border: 0 none;
  height: 24px;
}

.topnav a {
  float: left;
  color: #f2f2f2;
  text-align: center;
  padding: 16px 16px;
  text-decoration: none;
  font-size: 15px;
}

.topnav a:hover {
  background-color: #ddd;
  color: black;
}

.topnav a.topnav-logo:hover {
  background-color: inherit !important;
}

.topnav a.active {
  background-color: #0050f6;
  color: white;
}

.main-app {
  margin: 0;
  border: 0;
  padding: 12px;
}

/* sections and headers */

.flu-page-title1 {
  -webkit-font-smoothing: antialiased;
  color: #0078d4;
  font-size: 42px;
  font-weight: 100;
  flex-shrink: 1;
  margin: 0 0 12px 0;
  padding: 0;
  border: 0;
}

.flu-page-title2 {
  align-items: baseline;
  color: rgb(50, 49, 48);
  display: flex;
  font-size: 32px;
  font-weight: 600;
  line-height: 1;
  margin: 0 0 12px 0;
  border: 0;
  padding: 0;
}

.flu-page-title-sub1 {
  margin-left: 16px;
  font-size: 20px;
  font-weight: 400;
  color: rgb(96, 94, 92);
  font-variant-caps: small-caps;
}

.flu-page-title-sub2 {
  margin-left: 16px;
  font-size: 24px;
  font-weight: 400;
  color: rgb(96, 94, 92);
  font-variant-caps: small-caps;
}

.flu-heading1 {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  text-align: left;
  -webkit-font-smoothing: antialiased;
  -webkit-box-direction: normal;
  box-sizing: inherit;
  font: inherit;
  vertical-align: baseline;
  text-decoration: none;
  color: #323130;
  font-weight: 600;
  line-height: 1;
  font-size: 24px;
  outline: 0;
  margin: 0 0 12px 0;
  padding: 0;
  border: 0;
  font-variant: small-caps;
}

.flu-heading1b {
  -webkit-font-smoothing: antialiased;
  color: #605e5c;
  font-size: 28px;
  font-weight: 300;
  flex-shrink: 1;
  margin: 0 0 12px 0;
  padding: 0;
  border: 0;
}

.flu-heading2 {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  text-align: left;
  -webkit-font-smoothing: antialiased;
  -webkit-box-direction: normal;
  box-sizing: inherit;
  font: inherit;
  vertical-align: baseline;
  text-decoration: none;
  font-size: 20px;
  font-weight: 600;
  color: rgb(96, 94, 92);
  margin: 0 0 12px 0;
  padding: 0;
  border: 0;
}

.flu-heading2b {
  -webkit-font-smoothing: antialiased;
  color: #a19f9d;
  font-size: 21px;
  font-weight: 100;
  flex-shrink: 1;
  margin: 0 0 12px 0;
  padding: 0;
  border: 0;
}

.flu-bottomborder1 {
  border-bottom: solid 1px #a19f9d;
  padding-bottom: 10px;
}

.flu-section {
  -webkit-text-size-adjust: 100%;
  --sash-size: 4px;
  -webkit-tap-highlight-color: transparent;
  text-align: left;
  color: rgb(50, 49, 48);
  -webkit-font-smoothing: antialiased;
  -webkit-box-direction: normal;
  box-sizing: inherit;
  margin: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  text-decoration: none;
  background: rgb(247, 247, 247);
  /* background: rgb(250, 250, 250); */
  /* background: rgb(252, 252, 252); */
  /* background: #eee; */
  /* background: #fff; */
  padding: 28px;
  border-radius: 2px;
  background-clip: padding-box;
  box-shadow: 0 1.6px 3.6px 0 rgba(0, 0, 0, .2), 0 .3px .9px 0 rgba(0, 0, 0, .8);
  position: relative;
  transition: transform .3s cubic-bezier(.1, .9, .2, 1), opacity .3s cubic-bezier(.1, .9, .2, 1) 50ms, -webkit-transform .3s cubic-bezier(.1, .9, .2, 1);
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition-delay: 0s;
}

.flu-section-body {
  color: #323130;
  font-weight: 400;
  font-size: 16px;
}

.flu-section {
  display: table;
  width: 1000px;
  margin-bottom: 16px;
}

/* grids */

div.ms-Grid {
  padding: 0;
}

div.ms-Grid div.ms-Grid-row {
  padding-bottom: 8px;
}

/* other */

.ms-BasePicker-text {
  background-color: #fff;
}

/* debug info section */

.debug-wrapper {
  margin: 15px 0;
  border: dashed 1px red;
  padding: 10px;
  width: 800px;
  background-color: rgb(255 227 227);
}

.debug-wrapper input,
.debug-wrapper textarea {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
}

.debug-ul {
  color: red;
  max-width: 800px;
}

.debug2col {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}

/* special table  */

.sub-table {
  border-collapse: collapse;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  color: rgb(50, 49, 48);
}

.sub-table th {
  font-size: 14px;
  font-weight: 600;
  color: rgb(50, 49, 48);
}

.sub-table td,
.sub-table th {
  padding: 8px;
}

.sub-table tr {
  border-bottom: 2px solid #aaa;
}

.sub-table th {
  white-space: nowrap;
}

/* sfs assessment table */

.sub-table2 {
  border-collapse: collapse;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  color: rgb(50, 49, 48);
}

.sub-table2 th {
  font-size: 14px;
  font-weight: 600;
  color: rgb(50, 49, 48);
}

.sub-table2 td,
.sub-table2 th {
  padding: 8px 8px 8px 0;
}

.sub-table2 .ms-Dropdown-container {
  width: 100%;
}

.sub-table2 label {
  white-space: nowrap;
}

/* connection metrics table  */

.sub-table3 {
  border-collapse: collapse;
  width: 100%;
  font-size: 14px;
  color: rgb(50, 49, 48);
}

.sub-table3 th {
  /* text-align: center; */
  padding-bottom: 10px;
}

.sub-table3 th {
  font-weight: 600;
}

/* .sub-table3 td:first-of-type {
  font-weight: 600;
} */

.sub-table3 th,
.sub-table3 td {
  padding: 8px 8px 8px 0;
}

.sub-table3 div.ms-DatePicker span {
  display: none;
}

.sub-table3 tr.sep {
  border-top: 2px solid #aaa;
}

.sub-table3 tr:last-of-type {
  border-bottom: 2px solid #aaa;
}

.sub-table3 tr th,
.sub-table3 th {
  white-space: nowrap !important;
}

.alm4-table td:first-of-type {
  width: 100px;
}

.alm4-table label {
  white-space: nowrap;
}

/* .alm4-table .ms-TextField {
  width: 350px;
} */

.jodit-react-container {
  background-color: #fff;
}

/* overide disabled fluent ui controls */

button.ms-Button.ms-Button--primary.is-disabled {
  border: solid 1px #bbb !important;
  color: #999 !important;
}

div.ms-ComboBox.is-disabled {
  border: solid 1px #bbb !important;
  color: #999 !important;
}

/* validation errors */

.red-msg-text {
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 12px;
  font-weight: 400;
  color: rgb(164, 38, 44);
}

.red-msg-border {
  border-color: rgb(164, 38, 44);
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
}

/* used for datepicker, ajaxpicker, spin button(number) */

.invalid-datepicker .ms-TextField-fieldGroup,
.invalid-datepicker .ms-BasePicker-text,
.invalid-datepicker div[class^='spinButtonWrapper']::after {
  border: solid 1px rgb(164, 38, 44) !important;
}

/* disabled field bg color override */

.ms-TextField.bgGrey .ms-TextField-fieldGroup {
  background-color: rgb(247, 247, 247) !important;
}

/* rich text content in readonlymode */

.div-richtext-wrapper {
  margin: 0;
  border: 0;
  padding: 6px 10px;
  -webkit-text-size-adjust: 100%;
  --sash-size: 4px;
  -webkit-tap-highlight-color: transparent;
  text-align: left;
  -webkit-font-smoothing: antialiased;
  -webkit-box-direction: normal;
  box-sizing: inherit;
  font: inherit;
  vertical-align: baseline;
  text-decoration: none;
  background-color: white !important;
  border-radius: 2px;
  background-clip: padding-box;
  box-shadow: 0 1.6px 3.6px 0 rgba(0, 0, 0, .2), 0 .3px .9px 0 rgba(0, 0, 0, .8);
  position: relative;
  transition: transform .3s cubic-bezier(.1, .9, .2, 1), opacity .3s cubic-bezier(.1, .9, .2, 1) 50ms, -webkit-transform .3s cubic-bezier(.1, .9, .2, 1);
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition-delay: 0s;
  min-height: 25px;
}

.div-richtext-wrapper p {
  margin: 8px 0;
}

.fluSmallButton {
  height: 22px !important;
}

/* asset info status sold row color */

tr.assetinfo-tr-y tr,
tr.assetinfo-tr-y td {
  background-color: #e0e0e0 !important;
}

/* loan info active inactive row color */

tr.loaninfo-tr-active-n tr,
tr.loaninfo-tr-active-n td {
  background-color: #e0e0e0 !important;
  color: #888 !important;
}

/* notes */

.btnResetFields {
  font-size: 14px;
  font-weight: 600;
}

/* notes */

.ul-an-atts a,
.ul-an-atts a:active,
.ul-an-atts a:visited {
  text-decoration: none;
  color: #0078d4;
}

.ul-an-atts a:hover {
  text-decoration: underline;
}

/* notes */

.flu-ovr-msgbar-info {
  background-color: #dddddde7;
}

/* asset info table styles */

.asset-info-table th {
  /* font-weight: bold; */
  font-size: 15px;
}

.asset-info-address-row {
  padding-left: 20px;
}

/* notes */